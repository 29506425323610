<template>
    <vs-table
        ref="table"
        v-model="selected"
        :sst="true"
        :data="tableData"
        @selected="$router.push({ name: 'invoice', params: { pk: $event.id } })"
    >
        <div slot="header" class="flex items-center flex-grow justify-end">
            <div>
                <vs-input icon="search" placeholder="Search" v-model="search" />
            </div>
        </div>

        <template slot="thead">
            <vs-th>Date Created</vs-th>
            <vs-th>Description</vs-th>
            <vs-th>Amount</vs-th>
            <vs-th>Paid</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr
                    :data="tr"
                    :key="index"
                    v-for="(tr, index) in data"
                    :state="!tr.paid ? 'danger' : null"
                >
                    <vs-td>
                        {{ formatDate(tr.date_created) }}
                    </vs-td>

                    <vs-td>
                        <p>
                            {{ tr.description }}
                        </p>
                    </vs-td>
                    <vs-td> £{{ tr.amount }} </vs-td>
                    <vs-td>
                        <feather-icon
                            :icon="tr.paid ? 'CheckIcon' : 'XIcon'"
                            svg-classes="text-primary"
                        />
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'InvoicesTable',
    mixins: [textFormat],
    props: ['data', 'itemsPerPage', 'view'],
    computed: {
        tableData() {
            return this.data.data
        },
        search: {
            set(value) {
                if (value.length > 1 || value === '') {
                    this.searchQuery = value
                    this.$emit('search', value)
                }
            },
            get() {
                return this.searchQuery
            },
        },
    },
    data() {
        return {
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
        }
    },
    methods: {
        toggleSelect() {
            this.selected = []
            this.selectMultiple = !this.selectMultiple
        },
        setFilter(status, value) {
            this.status = status
            this.$emit('filter', value)
        },
        progressStatus(tableData) {
            if (tableData.client.code_completed) return 'Will Created'
            if (tableData.client.code_used) return 'Account Created'
            if (tableData.client.code_clicked) return 'Code Clicked'
            if (tableData.client.code_sent) return 'Code Sent'
        },
        gotoInvoice(event) {
            console.log('go to invoice please')
            console.log(event)
            this.$router.push({ name: 'invoice', params: { pk: event.id } })
        },
    },
}
</script>
